import { pluralize } from 'Classes/utils/WordingUtils';
import { getProductPackagingWeight } from 'Classes/utils/ProductPackagingUtils';
import { WORDINGS } from 'Classes/Wordings';

/**
 * Get the quantity of people a box can serve.
 *
 * @param {Object} item - The item object.
 * @param {number} [item.minPeople] - The minimum number of people the item can serve.
 * @param {number} [item.maxPeople] - The maximum number of people the item can serve.
 * @param {Object} [options] - The options object.
 * @param {string} [options.format='short'] - The format of the output string.
 * @returns {string} The quantity of people the item can serve.
 */
export const getBoxPeopleQuantity = (item, { format = 'short' } = {}) => {
  let str = '';
  if (item.minPeople || item.maxPeople) {
    str += item.minPeople;
    if (item.maxPeople && item.minPeople !== item.maxPeople) str += ` à `;
    str += format === 'long' ? pluralize(item.maxPeople, 'personne') : `${item.maxPeople} pers.`;
  }

  return str;
};

/**
 * Get the weight and people quantity for a box.
 *
 * @param {Object} box - The box object.
 * @param {number} [box.minPeople] - The minimum number of people the box can serve.
 * @param {number} [box.maxPeople] - The maximum number of people the box can serve.
 * @param {Object} [options] - The options object.
 * @param {string} [options.format='short'] - The format of the output string.
 * @returns {string} The weight and people quantity of the box.
 */
export const getWeightAndBoxPeopleQuantity = (box, { format = 'short' } = {}) => {
  const min = parseInt(box.minPeople, 10);
  const max = parseInt(box.maxPeople, 10);

  let str = getProductPackagingWeight(box, { format });
  if (min || max) {
    str += str ? ' - ' : '';
    str += getBoxPeopleQuantity(box, { format });
  }
  return str;
};

/**
 * Get the array of suppliers for a box.
 *
 * @param {Object} box - The box object.
 * @param {Array} [box.suppliers] - The array of suppliers.
 * @param {Array} box.products - The array of products in the box.
 * @returns {Array} The array of unique suppliers.
 */
export const getBoxSuppliersArray = (box) => {
  if (box.suppliers?.length) return box.suppliers;
  return [...new Set(box.products.map(product => product.supplier)
    .filter(Boolean))];
};

/**
 * Get the suppliers of a box as a comma-separated string.
 *
 * @param {Object} box - The box object.
 * @param {Array} [box.suppliers] - The array of suppliers.
 * @param {Array} box.products - The array of products in the box.
 * @returns {string} The comma-separated list of supplier names.
 */
export const getBoxSuppliers = (box) =>
  getBoxSuppliersArray(box)
    .map(({
      substituteName,
      firstname
    }) => substituteName || firstname)
    .filter(Boolean)
    .join(', ');

/**
 * Get the lock message for a box.
 *
 * @param {Object} box - The box object.
 * @param {Object} [box.deliveryDate] - The delivery date object.
 * @param {boolean} [box.deliveryDate.isCurrentWeek] - Whether the delivery date is in the current week.
 * @param {boolean} [box.isSoldOut] - Whether the box is sold out.
 * @returns {string|undefined} The lock message for the box.
 */
export const getBoxLockStatus = (box) => {
  if (!box?.deliveryDate?.isCurrentWeek) return WORDINGS.BOX_LOCKED.STATUS;
  if (box?.isSoldOut) return 'Épuisé';
  return undefined;
};
