<template>
  <div class="pay-form">
    <potager-form
      id="paygreen-form"
      @onSubmit="paygreenPayment">
      <p
        v-if="paymentMethod.name === PAYMENT_METHODS_CONSTANTS.PLUXEE.name"
        class="font-bold mb-4">
        Cartes sodexo : Pour être acceptée, votre carte Sodexo Pass Restaurant doit être de 2ème génération et
        labellisée CONECS.
      </p>

      <div id="paygreen-container" />

      <div id="paygreen-methods-container" />

      <transition name="fade">
        <div
          v-if="(!ready || isPaygreenLoading) && !isDisabled"
          class="payment__loader flex flex-col">
          <icon-ptcy-loader-spinner />

          <p
            v-if="ready"
            class="text-xs text-center p-4">
            <b>L'ajout de votre carte est en cours de traitement.</b>
            <br>
            Vous allez bientôt être redirigé.
            <br>
            Une validation 3DSecure peut vous être demandée. Merci de bien vouloir la valider.
            <br>
            <br>
            Merci de ne pas fermer cette page.
          </p>
        </div>
      </transition>

      <transition name="fade">
        <div
          v-if="isDisabled"
          class="payment__disabled" />
      </transition>

      <potager-row v-show="ready">
        <potager-col>
          <label class="payment__input-wrapper">
            <span
              :class="{
                'potager-input__label potager-input__label--external': true,
                'potager-input__label--invalid': status.cardNumber.error,
              }">
              Numéro de la carte
            </span>

            <div
              id="paygreen-pan-frame"
              ref="cardNumber"
              :class="{
                'payment__input': true,
                'payment__input--invalid': status.cardNumber.error,
                'payment__input--disabled': isPaygreenLoading,
              }" />
          </label>

          <potager-info
            v-if="status.cardNumber.error"
            size="small"
            status="warning">
            <template #icon>
              <icon-road-sign-warning />
            </template>
            {{ status.cardNumber.error.message }}
          </potager-info>
        </potager-col>

        <potager-col :col="(isTunnel || isModal) && !$mq.bp768 ? 6 : 12">
          <label class="payment__input-wrapper">
            <span
              :class="{
                'potager-input__label potager-input__label--external': true,
                'potager-input__label--invalid': status.cardExpiry.error,
              }">
              Date d'expiration
            </span>

            <div
              id="paygreen-exp-frame"
              ref="cardExpiry"
              :class="{
                'payment__input': true,
                'payment__input--invalid': status.cardExpiry.error,
                'payment__input--disabled': isPaygreenLoading,
              }" />
          </label>

          <potager-info
            v-if="status.cardExpiry.error"
            size="small"
            status="warning">
            <template #icon>
              <icon-road-sign-warning />
            </template>
            {{ status.cardExpiry.error.message }}
          </potager-info>
        </potager-col>

        <potager-col :col="(isTunnel || isModal) && !$mq.bp768 ? 6 : 12">
          <label class="payment__input-wrapper">
            <span
              :class="{
                'potager-input__label potager-input__label--external': true,
                'potager-input__label--invalid': status.cardCvc.error,
              }">
              Code de vérification
            </span>

            <div
              id="paygreen-cvv-frame"
              ref="cardCvc"
              :class="{
                'payment__input': true,
                'payment__input--invalid': status.cardCvc.error,
                'payment__input--disabled': isPaygreenLoading,
              }" />
          </label>

          <potager-info
            v-if="status.cardCvc.error"
            size="small"
            status="warning">
            <template #icon>
              <icon-road-sign-warning />
            </template>
            {{ status.cardCvc.error.message }}
          </potager-info>
        </potager-col>
      </potager-row>

      <potager-row>
        <potager-col>
          <p class="pt-4 pb-2">
            Veuillez cocher la case ci-dessous afin d'autoriser la liaison de votre carte à votre compte Potager City.
          </p>

          <div
            id="paygreen-reuse-checkbox-container"
            class="h-14" />
        </potager-col>
      </potager-row>

      <slot name="infos" />

      <div
        v-if="!hideSubmit"
        class="payment__submit-wrapper">
        <potager-button
          id="payButton"
          :full-width="!isModal || $mq.bp768"
          :is-disabled="!canSubmit"
          :is-loading="isPaygreenLoading"
          class="payment__submit min-w-40"
          theme="go-green"
          type="submit">
          Ajouter
        </potager-button>
      </div>

      <potager-info
        v-if="legendAuthorization"
        class="payment__legend"
        size="small">
        <template #icon>
          <icon-information-circle />
        </template>
        Demande d'autorisation de paiement entre 2 et 4 jours avant la livraison,
        prélèvement la veille de la livraison.
        <a
          href="https://potagercity.force.com/serviceclient/s/article/J-aperçois-une-ligne-sur-le-relevé-de-mon-compte-bancaire-quelques-jours-avant-la-livraison-Pourquoi"
          target="_blank">
          Plus d'infos</a>
      </potager-info>

      <potager-info
        v-if="legendCgv"
        class="payment__legend"
        size="small">
        <template #icon>
          <icon-information-circle />
        </template>
        En validant cette étape, vous acceptez l’intégralité de
        <potager-link
          :to="{ name: 'cgv' }"
          target="_blank">
          nos conditions générales de vente
        </potager-link>
        et notre politique de gestion de vos informations personnelles
      </potager-info>
    </potager-form>

    <div
      v-if="paymentMethod.alternativeIcons && paymentMethod.alternativeIcons.length > 0 && !hideIcons"
      :class="[
        { 'pb-0': isModal },
        'text-xl.5 flex justify-center items-center mt-2 bg-transparent'
      ]">
      <component
        :is="icon"
        v-for="(icon, index) in paymentMethod.alternativeIcons"
        :key="`icon-${index}`"
        :color="paymentMethod.colors.tertiary"
        class="mx-1" />

      <icon-conecs-full
        v-if="paymentMethod.name === PAYMENT_METHODS_CONSTANTS.PLUXEE.name"
        class="ml-2" />
    </div>
  </div>
</template>

<script>
import PaygreenMixin from 'Mixins/payments/PaygreenMixin';

import IconPtcyLoaderSpinner from 'Icons/regular/IconPtcyLoaderSpinner';
import IconRoadSignWarning from 'Icons/regular/IconRoadSignWarning';
import IconInformationCircle from 'Icons/regular/IconInformationCircle';
import IconConecsFull from 'Icons/color/IconConecsFull';

import { getPMByName } from 'Classes/payment-methods';
import PAYMENT_METHODS_CONSTANTS from 'Classes/payment-methods/Constants';

import PotagerButton from 'UI/PotagerButton';
import PotagerRow from 'UI/PotagerRow';
import PotagerCol from 'UI/PotagerCol';
import PotagerInfo from 'UI/PotagerInfo';
import PotagerForm from 'UI/Form/PotagerForm';

import COLORS from 'Settings/colors';

const { white } = COLORS;

export default {

  mixins: [
    PaygreenMixin,
  ],

  components: {
    PotagerForm,
    IconPtcyLoaderSpinner,
    IconRoadSignWarning,
    IconInformationCircle,
    IconConecsFull,
    PotagerButton,
    PotagerRow,
    PotagerCol,
    PotagerInfo,
  },

  data: () => ({
    colors: { white },
    cardNumber: null,
    cardExpiry: null,
    cardCvc: null,
    status: {
      cardNumber: {},
      cardExpiry: {},
      cardCvc: {},
    },
    isFilling: false,
    PAYMENT_METHODS_CONSTANTS,
  }),

  props: {
    context: {
      type: String,
      required: false,
      default: 'account',
    },
    hideIcons: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideSubmit: {
      type: Boolean,
      required: false,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    legendAuthorization: {
      type: Boolean,
      required: false,
      default: false,
    },
    legendCgv: {
      type: Boolean,
      required: false,
      default: false,
    },
    name: {
      type: String,
      required: true,
    },
    setPrimary: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    isTunnel() {
      return this.context === 'tunnel';
    },
    isModal() {
      return this.context === 'modal';
    },
    canSubmit() {
      return this.validCard && this.reusable;
    },
    paymentMethod() {
      return getPMByName(this.name);
    },
  },

  watch: {
    canSubmit: {
      handler(val, oldVal) {
        if (val !== oldVal || !oldVal) this.$emit('canSubmit', val);
      },
      immediate: true,
    },
    ready(ready) {
      if (ready) {
        window.paygreenjs.attachEventListener(window.paygreenjs.Events.PAN_FIELD_FULFILLED, () => {
          window.paygreenjs.focus('exp');
        });
        window.paygreenjs.attachEventListener(window.paygreenjs.Events.EXP_FIELD_FULFILLED, () => {
          window.paygreenjs.focus('cvv');
        });
      }
    },
    name: {
      handler(name, oldName) {
        if (name !== oldName) {
          this.cardNumber = null;
          this.cardExpiry = null;
          this.cardCvc = null;
          this.status = {
            cardNumber: {},
            cardExpiry: {},
            cardCvc: {},
          };
        }
      },
      immediate: true,
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.initPaygreen();
    });
  },
};
</script>
