<template>
  <panel
    :back-route="getBackRoute"
    title="Remplacer mon panier">
    <div class="panel-section">
      <p class="panel-section__p mb-8 bp640:mb-4">
        Votre panier "<b>{{ getSubcriptionBoxName }}</b>" de cette semaine ne vous convient pas ? Vous pouvez à tout
        moment
        l’annuler ou le remplacer par un autre panier de votre choix.
      </p>

      <p class="panel-section__subtitle">
        Choisissez un panier de remplacement
      </p>

      <potager-row
        class="panel-section__line"
        tag="ul">
        <potager-col
          v-for="(box, key) in boxesAvailable"
          :key="key"
          col="6"
          tag="li">
          <card-product
            :box="box"
            replace
            small>
            <template #footer>
              <potager-button
                :is-disabled="isBoxDisabled || box.isSoldOut"
                :is-loading="isBoxLoading(box)"
                :theme="box.isSoldOut ? undefined : 'go-green'"
                :tooltip="!box.loading ? getBoxLockStatus(box) : undefined"
                full-width
                prevent
                size="small"
                @onClick="replaceSubscriptionOrder(box.id)">
                Remplacer
              </potager-button>
            </template>
          </card-product>
        </potager-col>
      </potager-row>
    </div>
  </panel>
</template>

<script>
import { mapGetters } from 'vuex';
import { REPLACE_SUBSCRIPTION_PRODUCT, CONFIRM_SHADOW_BASKET_ACTION } from 'Stores/types/shadowBasketActionsTypes';

import ShadowBasketMixin from 'Mixins/ShadowBasketMixin';

import Panel from 'Components/panel/Panel';
import CardProduct from 'Components/cards/CardProduct';

import PotagerRow from 'UI/PotagerRow';
import PotagerCol from 'UI/PotagerCol';
import PotagerButton from 'UI/PotagerButton';

import MetaInfosService from 'Classes/services/MetaInfoService';
import { SHADOW_BASKET_TYPE } from 'Classes/Constants';
import { getBoxLockStatus } from 'Classes/utils/BoxesUtils';

export default {

  mixins: [
    ShadowBasketMixin,
  ],

  components: {
    Panel,
    PotagerRow,
    PotagerCol,
    PotagerButton,
    CardProduct,
  },

  data: () => ({
    boxesAvailable: [
      { loading: true },
      { loading: true },
      { loading: true },
      { loading: true },
      { loading: true },
      { loading: true },
    ],
  }),

  computed: {
    ...mapGetters('user', [
      'getNextEditableOrder',
      'getSubscriptionOrder',
      'getSubscription',
    ]),
    getSubcriptionBoxName() {
      return this.getSubscription?.product?.name;
    },
    getBackRoute() {
      return this.getSubscriptionOrder?.id ? {
        name: 'mon-compte_orders_detail',
        params: { orderId: this.getSubscriptionOrder.id },
      } : null;
    },
    isBoxDisabled() {
      return this.$wait.is(REPLACE_SUBSCRIPTION_PRODUCT);
    },
  },

  methods: {
    getBoxLockStatus,
    getBoxesAvailable() {
      this.$api.box.findAllByBasket(SHADOW_BASKET_TYPE)
        .then(({ data }) => {
          this.boxesAvailable = data.data.results.filter((e) => !e.isComplementary && !e.isTypeFormule);
        });
    },
    replaceSubscriptionOrder(productId) {
      if (productId && this.getNextEditableOrder) {
        this.replaceSubscriptionProduct({ id: productId }, this.getNextEditableOrder);
      } else {
        this.$notify({
          type: 'error',
          title: 'Une erreur est survenue',
          text: 'Impossible de remplacer votre panier pour le moment',
        });
      }
    },
    isBoxLoading(box) {
      return this.$wait.is([
        `${REPLACE_SUBSCRIPTION_PRODUCT}_${box.id}`,
        CONFIRM_SHADOW_BASKET_ACTION,
      ]);
    },
  },

  created() {
    this.getBoxesAvailable();
  },

  head: MetaInfosService.generate({
    title: 'Remplacer mon panier',
  }),

};
</script>
