<template>
  <potager-link
    v-gtm
    :class="{
      'subscriber-bar__item h-full': true,
      'subscriber-bar__item--has-link': redirect,
      'subscriber-bar__item--vertical-stretch': verticalStretch,
      'subscriber-bar__item--warning': isWarning,
    }"
    :to="redirect">
    <div
      :class="{
        'subscriber-bar__item__illustration': true,
        'subscriber-bar__item__illustration--with-icon': $slots.icon || !image,
        'subscriber-bar__item__illustration--with-image': image,
      }">
      <potager-picture
        v-if="image"
        :src="image"
        background
        class="subscriber-bar__item__pic" />

      <div
        v-else-if="$slots.icon || isLoading"
        class="subscriber-bar__item__icon">
        <icon-ptcy-loader-spinner v-if="isLoading" />
        <slot
          v-else
          name="icon" />
      </div>
    </div>

    <p class="subscriber-bar__item__text">
      <span class="subscriber-bar__item__text__title">
        {{ capitalize(text) }}
      </span>
      <slot />
      <template v-if="legend">
        <span
          ref="legend"
          :class="{
            'subscriber-bar__item__text__legend': true,
            'subscriber-bar__item__text__legend--ellipsis': ellipsis,
          }"
          v-html="legend" />
        <span
          v-if="isEllipsis"
          class="subscriber-bar__item__text__legend__readmore"
          @click.prevent.stop="readMore">
          Lire la suite
        </span>
      </template>
    </p>

  </potager-link>
</template>

<script>
import IconPtcyLoaderSpinner from 'Icons/regular/IconPtcyLoaderSpinner';
import PotagerPicture from 'UI/PotagerPicture';

import { capitalize } from 'Classes/utils/WordingUtils';

import COLORS from 'Settings/colors';
import ModalInfo from 'Modals/ModalInfo';

const { white } = COLORS;

export default {

  components: {
    IconPtcyLoaderSpinner,
    PotagerPicture,
  },

  data: () => ({
    colors: { white },
    isEllipsis: false,
  }),

  props: {
    redirect: {
      type: Object,
      required: false,
      default: null,
    },
    text: {
      type: String,
      required: false,
      default: null,
    },
    legend: {
      type: String,
      required: false,
      default: null,
    },
    legendSmall: {
      type: Boolean,
      required: false,
      default: false,
    },
    image: {
      type: Object,
      required: false,
      default: null,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    ellipsis: {
      type: Boolean,
      required: false,
      default: false,
    },
    verticalStretch: {
      type: Boolean,
      required: false,
      default: false,
    },
    isWarning: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  methods: {
    capitalize,
    readMore() {
      this.$modal.open(ModalInfo, {
        title: capitalize(this.text),
        text: this.legend,
      });
    },
    checkIfEllipsis() {
      const { legend } = this.$refs;

      if (legend && this.ellipsis) {
        const css = window.getComputedStyle(legend);
        const rect = legend.getBoundingClientRect();
        const clone = document.createElement('div');
        const content = document.createTextNode(this.legend);
        clone.appendChild(content);

        clone.style.fontSize = css.getPropertyValue('font-size');
        clone.style.lineHeight = css.getPropertyValue('line-height');
        clone.style.fontWeight = css.getPropertyValue('font-weight');
        clone.style.width = `${rect.width}px`;
        clone.style.position = 'absolute';
        clone.style.visibility = 'hidden';
        clone.style.top = 0;
        clone.style.left = 0;
        clone.style.pointerEvents = 'none';

        document.body.appendChild(clone);

        const cloneRect = clone.getBoundingClientRect();
        this.isEllipsis = cloneRect.height > rect.height;

        clone.parentNode.removeChild(clone);
      } else {
        this.isEllipsis = false;
      }
    },
  },

  mounted() {
    this.checkIfEllipsis();

    window.addEventListener('resize', this.checkIfEllipsis);
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.checkIfEllipsis);
  },

};
</script>
