<template>
  <panel
    :is-loading="!newOrder"
    title="C'est tout bon !">
    <div
      v-if="newOrder"
      class="panel-section--transparent text-center">
      <icon-illu-delivery class="text-[160px] text-british-racing m-2.5" />

      <div class="my-4">
        <b>Merci pour votre commande.</b>
        <br>
        Elle sera livrée le <b>{{ getGddFormattedDay(newOrder.groupDeliveryDay, { context: Context.Basket }) }}</b>
        <template
          v-if="newOrder.deliveryPoint?.isHomeDelivery">
          à votre domicile.
        </template>
        <template v-else>
          au point relais <b>{{ newOrder.deliveryPoint?.groupName }}</b>.
        </template>
      </div>

      <potager-button
        :to="{ name: 'mon-compte_orders_detail', params: { orderId: newOrder.id } }"
        theme="go-green">
        Voir ma commande
      </potager-button>
    </div>
  </panel>
</template>

<script>
import { mapGetters } from 'vuex';

import GtmMixin from 'Mixins/GtmMixin';

import Panel from 'Components/panel/Panel';

import COLORS from 'Settings/colors';

import { formatPrice } from 'Classes/utils/PricesUtils';
import MetaInfosService from 'Classes/services/MetaInfoService';
import { PRICE_ONE_SHOT } from 'Classes/Constants';
import { getGddFormattedDay } from 'Classes/utils/DlpUtils';

import IconIlluDelivery from 'Icons/graphics/IconIlluDelivery';

import PotagerButton from 'UI/PotagerButton.vue';
import { Context } from 'potagerlogic/dist/Enums/Context';

const {
  sushi,
  white
} = COLORS;

export default {
  mixins: [
    GtmMixin,
  ],

  components: {
    PotagerButton,
    Panel,
    IconIlluDelivery,
  },

  data: () => ({
    PRICE_ONE_SHOT,
    Context,
    colors: {
      sushi,
      white
    },
  }),

  computed: {
    ...mapGetters('user', [
      'getUser',
      'getOrderById',
    ]),
    priceCategory() {
      return this.newOrder && this.newOrder.priceCategory ? this.newOrder.priceCategory : PRICE_ONE_SHOT;
    },
    newOrder() {
      return this.getOrderById(this.$potagerRoute.params.orderId);
    },
    getOrderCardId() {
      return this.newOrder?.stripePayment?.currentStripePayment?.blueCard;
    },
  },

  methods: {
    getGddFormattedDay,
    formatPrice,
    close() {
      this.$router.push({ path: this.$route.path })
        .catch(() => {
        });
    },
  },

  mounted() {
    this.trackPurchaseSuccess(this.newOrder, this.getUser, null, this.getOrderCardId);
  },

  head: MetaInfosService.generate({
    title: 'Panier validé',
  }),
};
</script>
